import { createStore } from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

const state = {
  currentPage: "home",
  mobileView: false,
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
});
